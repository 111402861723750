/* eslint-disable no-unused-vars */
import ApiService from "@/services/api.service";
import SettingsService from "@/services/settings.service.js";
import authService from "@/services/auth.service";
const state = {
  user: null,
  role: null,
  isLoggedIn: false,
};

const getters = {
  isLoggedIn: (state) => {
    return state.isLoggedIn;
  },
  user: (state) => {
    return state.user;
  },
  role: (state) => {
    return state.role;
  },
};

const actions = {
  getUser() {
    return authService.getUser();
  },
  setUser({ commit }, value) {
    console.log("set user called: ", value);
    commit("setUser", value);
  },
  setRole({ commit }, value) {
    commit("setRole", value);
  },
  setIsLoggedIn({ commit }, value) {
    commit("setIsLoggedIn", value);
  },
  login() {
    authService.login();
  },
  logout({ commit }, value) {
    authService.startLogout(value);
  },
  getRoles() {
    return authService.getRoles();
  },
  silentRefresh() {
    return authService.silentRefresh();
  },
};

const mutations = {
  setUser(state, value) {
    state.user = value;
  },
  setRole(state, value) {
    state.role = value;
  },
  setIsLoggedIn(state, value) {
    state.isLoggedIn = value;
  },
};

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
