var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 pt-md-4 pt-md-0 page-container"},[_c('v-row',{staticClass:"my-0",attrs:{"justify":"center","no-gutters":""}},[_c('v-card',{staticClass:"pa-0",attrs:{"flat":"","width":"1600","color":"transparent"}},[_c('v-card-text',{staticClass:"text-left text-subtitle-1 pa-0 pt-md-1"},[_c('v-row',{staticClass:"px-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"sm":"4","md":"6"}},[_c('v-col',{class:`
                text-h6 text-md-h4
                pb-0 
                pt-2 pt-md-5
                ${_vm.pageIcon.PageTitleColor}--text
                text-left`,attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t("components.contact.subtitle"))+" ")]),_c('v-col',{class:`text-subtitle-1 text-md-h6
                pb-2 pt-0 pt-sm-4
                font-weight-regular
                text-left`,attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.$t("components.contact.subtitle1"))),_c('br'),_vm._v(_vm._s(_vm.$t("components.contact.subtitle2")))]),_c('v-col',{domProps:{"innerHTML":_vm._s(_vm.contactInfo[_vm.$vuetify.lang.current.toUpperCase()])}})],1),(_vm.isPensionsPortal)?_c('v-col',{staticClass:"pr-sm-2",attrs:{"cols":"12","sm":"8","md":"6"}},[_c('contact-form-ccwipp')],1):_c('v-col',{staticClass:"pr-sm-2",attrs:{"cols":"12","sm":"8","md":"6"}},[_c('contact-form')],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }