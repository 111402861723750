var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"py-4"},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.internalMember)?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('no-autocomplete-textfield',{staticClass:"required highlight",attrs:{"disabled":"","outlined":"","label":_vm.$t(
              'components.registration_completion.contacts_form.email_readonly'
            ),"rules":[_vm.$rules.required, _vm.$rules.email]},model:{value:(_vm.internalMember.EmailAddress),callback:function ($$v) {_vm.$set(_vm.internalMember, "EmailAddress", $$v)},expression:"internalMember.EmailAddress"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('no-autocomplete-textfield',{attrs:{"clearable":"","outlined":"","label":_vm.$t(
              'components.registration_completion.contacts_form.secondary_email'
            ),"rules":[_vm.$rules.email]},model:{value:(_vm.internalMember.SecondaryEmail),callback:function ($$v) {_vm.$set(_vm.internalMember, "SecondaryEmail", $$v)},expression:"internalMember.SecondaryEmail"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('no-autocomplete-textfield',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.telephoneMask),expression:"telephoneMask"}],attrs:{"clearable":"","outlined":"","label":_vm.$t(
              'components.registration_completion.contacts_form.mobile_phone'
            ),"rules":[_vm.rules.phone]},model:{value:(_vm.internalMember.MobilePhone),callback:function ($$v) {_vm.$set(_vm.internalMember, "MobilePhone", $$v)},expression:"internalMember.MobilePhone"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('no-autocomplete-textfield',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.telephoneMask),expression:"telephoneMask"}],attrs:{"clearable":"","outlined":"","label":_vm.$t('components.registration_completion.contacts_form.home_phone'),"rules":[_vm.rules.phone]},model:{value:(_vm.internalMember.HomePhone),callback:function ($$v) {_vm.$set(_vm.internalMember, "HomePhone", $$v)},expression:"internalMember.HomePhone"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-select',{class:_vm.internalMember.HomePhone || _vm.internalMember.Mobile
              ? 'required'
              : '',attrs:{"outlined":"","label":_vm.$t(
              'components.contact_information_expansion_panel.preferred_phone'
            ),"items":_vm.preferredPhone,"rules":_vm.internalMember.HomePhone || _vm.internalMember.Mobile
              ? [_vm.$rules.required]
              : []},model:{value:(_vm.internalMember.PreferredPhone),callback:function ($$v) {_vm.$set(_vm.internalMember, "PreferredPhone", $$v)},expression:"internalMember.PreferredPhone"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"required",attrs:{"outlined":"","label":_vm.$t('components.registration_completion.contacts_form.language'),"items":_vm.languages,"rules":[_vm.$rules.required]},model:{value:(_vm.internalMember.Language),callback:function ($$v) {_vm.$set(_vm.internalMember, "Language", $$v)},expression:"internalMember.Language"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"required",attrs:{"outlined":"","label":_vm.$t(
              'components.registration_completion.contacts_form.communication'
            ),"rules":[_vm.$rules.required],"items":_vm.enumModels.CommunicationPreference,"item-text":_vm.$vuetify.lang.current === 'en' ? 'textEn' : 'textFr',"item-value":"value"},model:{value:(_vm.internalMember.Communication),callback:function ($$v) {_vm.$set(_vm.internalMember, "Communication", $$v)},expression:"internalMember.Communication"}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }