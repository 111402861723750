<template>
  <iframe
    :src="source + '#view=fit'"
    :title="title"
    height="100%"
    width="100%"
  />
</template>

<script>
export default {
  props: {
    source: String,
    title: String,
  },
};
</script>
